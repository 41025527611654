.ag-theme-quartz,
.ag-theme-quartz {
  --ag-foreground-color: #203543;
  --ag-background-color: rgb(254, 254, 254);
  --ag-header-foreground-color: #203543;
  --ag-header-background-color: #f3f6fa;
  --ag-odd-row-background-color: #f3f6fa;
  --ag-row-hover-color: #c1e5ff;
}
.ag-theme-quartz .ag-header-group-cell,
.ag-theme-quartz .ag-header-group-cell {
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
}
.ag-theme-quartz .ag-header-cell,
.ag-theme-quartz .ag-header-cell {
  font-weight: bold;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
}

.edited-cell {
  background-color: #f0facd;
}

.ag-details-row {
  background-color: #dce0e4;
  padding-top: 0;
  padding-bottom: 0;
}

.ag-theme-quartz .ag-details-row {
  padding: calc(var(--ag-grid-size) * 0.5);
  flex: auto;
}

.inner-row-class {
  background-color: #ececec;
}

/* .inner-row-class:hover {
  background-color: #1ada8d;
} */

.custom-header {
  background-color: #e0e0e0;
  font-weight: bold;
  padding-top: 0;
  padding-bottom: 0;
  height: 30px;
}

.custom-header:hover {
  background-color: #e0e0e0 !important;
}

.ag-theme-quartz div.ag-row-footer {
  background-color: #fff1d3 !important;
  font-weight: bold;
}
