@import '../../assets/scss/config';

.labelDesktopStyle {
  font-size: 0.85rem;
  font-weight: 400;
  width: 180px;
  word-wrap: break-word;
  box-sizing: border-box;
  flex-shrink: 0;
}

@media (max-width: 500px) {
  .labelDesktopStyle {
    display: none;
  }
}

.textFieldRootStyle {
  fieldset {
    border-radius: $border-radius - 6 !important;
  }
}

.errorText {
  position: relative;
  padding-left: 13px;
}
